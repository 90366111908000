/* 전체 앱 */
.chat-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f8f8f8; /* iOS 스타일 배경 */
  color: #333;
}

/* 상단바 고정 */
.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  border-bottom: 1px solid #e5e5e5;
}

/* 상단바 제목 */
.fixed-header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #007aff; /* iOS 블루 */
}

/* 계정 관련 버튼 */
.account-actions {
  display: flex;
  gap: 10px;
}

.account-actions button {
  background-color: transparent;
  color: #007aff; /* iOS 블루 */
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.account-actions button:hover {
  background-color: #f0f0f5;
  border-radius: 10px;
}

/* 상단바 아래 전체 콘텐츠 */
.content {
  display: flex;
  height: calc(100vh - 60px); /* 상단바 높이만큼 빼기 */
  margin-top: 60px;
  background-color: #f8f8f8;
}

/* 사이드바 */
.sidebar {
  width: 25%;
  background-color: #ffffff;
  padding: 15px;
  border-right: 1px solid #e5e5e5;
  overflow-y: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.chat-rooms {
  margin-bottom: 20px;
}

.chat-room {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s;
}

.chat-room:hover {
  background-color: #f0f0f5;
}

.chat-room.selected {
  background-color: #e6f0ff;
  border: 1px solid #007aff; /* iOS 블루 */
}

.chat-room p {
  margin: 0;
  flex: 1;
  color: #333;
  font-size: 14px;
}

/* 삭제 버튼 */
.delete-room-btn {
  background-color: #ff3b30; /* iOS 레드 */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s;
}

.delete-room-btn:hover {
  background-color: #d32e2e;
}

/* 채팅방 생성 */
.create-chat input {
  width: 70%;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.create-chat button {
  background-color: #007aff; /* iOS 블루 */
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
}

.create-chat button:hover {
  background-color: #005bb5;
}

/* 채팅창 */
.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.chat-header {
  background-color: #007aff;
  color: white;
  padding: 15px;
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.chat-messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: #f8f8f8;
}

.message-wrapper {
  display: flex;
  margin: 10px 0;
  padding: 0 15px;
}

.user-message-wrapper {
  justify-content: flex-start;
}

.bot-message-wrapper {
  justify-content: flex-end;
}

.message {
  max-width: 60%;
  padding: 10px 15px;
  border-radius: 18px;
  font-size: 14px;
  word-wrap: break-word;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.user-message {
  background-color: #007aff;
  color: white;
  border-bottom-left-radius: 0;
}

.bot-message {
  background-color: #f1f1f1;
  color: #333;
  border-bottom-right-radius: 0;
}

/* 입력 영역 */
.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f8f8f8;
  gap: 10px;
}

/* 텍스트 입력창 */
.message-input textarea {
  flex: 1; /* 공간을 최대한 차지 */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  resize: none;
  font-size: 14px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: all 0.2s;
}

.message-input textarea:focus {
  border-color: #007aff; /* iOS 블루 */
  box-shadow: 0px 2px 4px rgba(0, 122, 255, 0.2);
}

/* 입력창과 버튼 그룹 */
.input-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* 파일 업로드 버튼 */
.file-upload-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #007aff;
  color: white;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.file-upload-btn input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* 전송 버튼 */
.send-btn {
  background-color: #007aff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}

.send-btn:hover {
  background-color: #005bb5;
  box-shadow: 0px 2px 6px rgba(0, 91, 181, 0.2);
}

/* 선택되지 않은 채팅 안내 */
.no-chat-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 16px;
  color: #555;
}

/* 업로드 상태 */
.upload-status {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  text-align: center;
}
