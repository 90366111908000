/* 전체 페이지 스타일 */
.google-login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #4a90e2, #50e3c2); /* 멋진 배경 그라데이션 */
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  color: #fff;
  text-align: center;
}

/* 로그인 카드 스타일 */
.google-login-card {
  background: rgba(255, 255, 255, 0.2); /* 반투명 배경 */
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
}

/* 제목 스타일 */
.google-login-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* 설명 스타일 */
.google-login-description {
  font-size: 16px;
  margin-bottom: 30px;
}

/* Google 로그인 버튼 */
.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.google-login-button:hover {
  background: #f7f7f7;
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Google 아이콘 */
.google-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: url('https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg')
    no-repeat center center;
  background-size: contain;
}
