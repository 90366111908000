/* 전체 화면 스타일 */
.profile-setup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #00c6ff, #0072ff); /* 멋진 배경 그라데이션 */
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  color: #fff;
}

/* 프로필 카드 스타일 */
.profile-setup-card {
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 400px;
}

/* 제목 스타일 */
.profile-setup-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* 설명 텍스트 스타일 */
.profile-setup-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

/* 입력 필드 스타일 */
.profile-setup-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: border-color 0.3s;
}

.profile-setup-input:focus {
  border-color: #0072ff;
}

/* 버튼 스타일 */
.profile-setup-button {
  background: #0072ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  width: 100%;
}

.profile-setup-button:hover {
  background: #0056d4;
  transform: translateY(-2px);
}

/* 성공 메시지 스타일 */
.profile-setup-success {
  margin-top: 20px;
  font-size: 14px;
  color: #28a745;
}
